import {ParagraphText} from '@thryvlabs/maverick'
import React, {useState, useEffect} from 'react'
import {getRemainingTimeFromMS} from '../utils/get-remaining-time-from-ms'
import {Modal} from '@thryvlabs/maverick'
import {useAuthClient} from '../utils/use-auth-client'
import {toast} from 'react-toastify'

const defaultRemainingTime = {
  minutes: '60',
  seconds: '00',
}

function CountdownTimer({
  countdownTimeMS,
  creativeApptId,
  kickoffApptId,
  countryIsoValue,
  setKickoffApptId,
  setCreativeApptId,
  prevStep,
  setCreativeCallDate,
  setOnboardingCallDate,
  frontendCcPaymentDetails,
  updateApptOnExpire,
}) {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime)

  const client = useAuthClient()
  const cancelDemo = React.useCallback(async () => {
    const creativeData = {
      type: 'creative',
      country: countryIsoValue,
      demoApptId: creativeApptId,
    }
    const kickoffData = {
      type: 'onboarding',
      country: countryIsoValue,
      demoApptId: kickoffApptId,
    }
    try {
      if (creativeApptId) {
        client(`plans/creative`, {
          data: {...creativeData},
          method: 'DELETE',
        })
        updateApptOnExpire('creative', creativeApptId)
      }
      if (kickoffApptId) {
        client(`plans/onboarding`, {
          data: {...kickoffData},
          method: 'DELETE',
        })
        updateApptOnExpire('onboarding', kickoffApptId)
      }
      setCreativeApptId(null)
      setKickoffApptId(null)
      setCreativeCallDate(null)
      setOnboardingCallDate(null)
    } catch (e) {
      toast.warn('There was a problem with the cancellation')
    }
  }, [
    client,
    countryIsoValue,
    creativeApptId,
    kickoffApptId,
    setCreativeApptId,
    setCreativeCallDate,
    setKickoffApptId,
    setOnboardingCallDate,
  ])
  useEffect(() => {
    if (
      Number(remainingTime.seconds) + Number(remainingTime.minutes) * 60 >=
      1
    ) {
      const intervalId = setInterval(() => {
        updateTime(countdownTimeMS)
      }, 1000)
      return () => clearTimeout(intervalId)
    } else {
      cancelDemo()
    }
  }, [
    cancelDemo,
    client,
    countdownTimeMS,
    countryIsoValue,
    creativeApptId,
    kickoffApptId,
    remainingTime,
    setCreativeApptId,
    setKickoffApptId,
  ])

  useEffect(() => {
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault()
      return (ev.returnValue = 'Are you sure you want to close?')
    })
  }, [])

  function updateTime(countdown) {
    setRemainingTime(getRemainingTimeFromMS(countdown))
  }
  return remainingTime.seconds + remainingTime.minutes * 60 > 0 ? (
    <>
      <ParagraphText
        variant={'reg'}
        color={'thryv-orange-500'}
        className={`font-weight-bold ${
          frontendCcPaymentDetails ? 'm-0' : 'mt-2'
        }`}
      >
        <span style={{color: frontendCcPaymentDetails ? '#FF5000' : 'gray'}}>
          Appointment(s){' '}
        </span>{' '}
        Expire in:{' '}
        <span style={{fontSize: '16px'}}>
          {remainingTime.minutes}:{remainingTime.seconds}
        </span>
      </ParagraphText>
    </>
  ) : (
    <Modal
      variant="default"
      title="Scheduled Appointments"
      btnType="form"
      disableBackdropClick
      footer
      action
      hideX
      btnActionText="OK"
      btnAction={() => prevStep()}
      width={'700px'}
      openOnLoad
    >
      <ParagraphText
        className="text-center"
        color={'thryv-neutral'}
        variant="reg"
      >
        Your appointments have expired. Please reschedule.
      </ParagraphText>
    </Modal>
  )
}

export default CountdownTimer
