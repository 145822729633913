import React, {useContext} from 'react'
import {Modal} from '@thryvlabs/maverick'
import {ParagraphText as Text, ModalTitle} from '@thryvlabs/maverick'
import {useNavigate} from 'react-router-dom'
import {headerContext} from '../context/header-context'
import {useFlags} from 'launchdarkly-react-client-sdk'

const CancelOrderModal = ({
  cancelUrl,
  order5NUId,
  isCancelOrder5,
  cancelOrder5,
}) => {
  const {isHeaderVisible, setIsHeaderVisible} = useContext(headerContext)
  const navigate = useNavigate()
  const {frontendSideNav} = useFlags()

  function handleClick() {
    if (isCancelOrder5 && order5NUId) {
      cancelOrder5('cancel-order', 'new-upgrade', cancelUrl)
    }
    navigate(cancelUrl)

    if (frontendSideNav) {
      let elem = document.getElementById('app-container')
      elem.style.gridTemplateAreas =
        '"header header" "sidebar main-content" "sidebar footer"'
    }

    if (!isHeaderVisible) {
      setIsHeaderVisible(true)
    }
  }

  return (
    <Modal
      id="cancel-order-modal"
      variant="default"
      btnText="Cancel Order"
      btnType="primary"
      btnActionText="CANCEL ORDER"
      btnAction={handleClick}
      footer
      action
      footerCancel
    >
      <ModalTitle variant="subtitle" size="lg">
        Are you sure you want to cancel this order?
      </ModalTitle>
      <Text variant="reg">
        If you cancel, no previous progress will be saved.
      </Text>
    </Modal>
  )
}

export {CancelOrderModal}
