import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {toast} from 'react-toastify'
import * as yup from 'yup'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import validator from 'validator'
import {PhoneNumberUtil} from 'google-libphonenumber'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAuth0} from '@auth0/auth0-react'
import {ParagraphText, Modal, ModalTitle} from '@thryvlabs/maverick'
import {useAuthClient} from '../utils/use-auth-client'
import {useUserRoles} from '../utils/use-user-roles'
import {useAsync} from '../utils/use-async'
import {NewOrderLayout} from '../components/new-order-layout'
import {LoadingTimelineModal} from '../components/loading-timeline-modal'
import {AccountInformationForm} from '../components/account-information-components/account-information-form'
import {InvoiceInformation} from '../components/account-information-components/invoice-information'

const phoneUtil = PhoneNumberUtil.getInstance()

const DIFFERENT_SALES_REP_OPTIONS = [
  {name: 'Sales Rep', value: true},
  {name: 'Partner', value: false},
  {name: 'No', value: 'NO'},
]
const OVERRIDE_SST_OPTIONS = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]
const REQUIRED_FIELD_MESSAGE = 'This field is required'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'
const SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'
const VALID_EMAIL_MESSAGE = 'Must be a valid email'

const formatPostalCode = (postCode, country) => {
  if (country === 'CA' && postCode) {
    return postCode.slice(0, 3) + ' ' + postCode.slice(3)
  }
  return postCode
}

const SCHEMA = yup.object().shape({
  leadIDRequired: yup.boolean(),
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  xpCode: yup.string(),
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email(VALID_EMAIL_MESSAGE),
  company: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  leadID: yup.string().when('leadIDRequired', leadIDRequired => {
    if (leadIDRequired === true) {
      return yup
        .string()
        .required(REQUIRED_FIELD_MESSAGE)
        .min(3, MIN_CHARACTERS_REQUIRED)
        .max(120, MAX_CHARACTERS_REQUIRED)
    }
    return yup.string()
  }),
  postCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-postCode-format',
      'Incorrect post code format',
      function (value) {
        if (!value) {
          return false
        }
        const country = this.options.context.componentProps.selectedCountry
        if (country === 'BB') {
          return value.match(/^[A-Za-z]{2}\d{5}$/)
        }
        if (country === 'KY') {
          return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
        }
        if (country === 'CA') {
          return value.match(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/)
        }
        return validator.isPostalCode(value, country)
      },
    ),
  address: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  city: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  state: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  mobile: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.options.context.componentProps.selectedCountry
          value = value.replace(/[^\d]/g, '')
          if (country === 'US' && value.length > 10) {
            return false
          }
          const parsedPhone = phoneUtil.parse(value, country)
          return phoneUtil.isValidNumber(parsedPhone)
        } catch {
          return false
        }
      },
    ),
  companyPhone: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.options.context.componentProps.selectedCountry
          value = value.replace(/[^\d]/g, '')
          if (country === 'US' && value.length > 10) {
            return false
          }
          const parsedPhone = phoneUtil.parse(value, country)
          return phoneUtil.isValidNumber(parsedPhone)
        } catch {
          return false
        }
      },
    ),
  differentSalesRep: yup
    .object()
    .shape({
      name: yup.string().required(REQUIRED_OPTION_MESSAGE),
      value: yup.string().required(REQUIRED_OPTION_MESSAGE),
    })
    .optional(),

  overrideRep: yup
    .object()
    .shape({
      name: yup.string().required(REQUIRED_OPTION_MESSAGE),
      value: yup.string().required(REQUIRED_OPTION_MESSAGE),
    })
    .optional(),

  salesRepCode: yup.string().when('differentSalesRep', {
    is: differentSalesRep => differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  overrideRepCode: yup.string().when('overrideRep', {
    is: overrideRep => overrideRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  abn: yup.string().test('validate-abn', function (value) {
    const lowerCountry =
      this.options.context.componentProps.selectedCountry.toLowerCase()
    if (lowerCountry === 'au' && (!value || value.length !== 11)) {
      return this.createError({message: 'Please enter a valid, 11-digit ABN'})
    }
    if (lowerCountry === 'nz') {
      if (!value) return true
      if (value && value.length !== 13) {
        return this.createError({
          message: 'Please enter a valid, 13-digit NZBN',
        })
      }
    }
    return true
  }),
  salesRepInfo: yup.string().when('differentSalesRep', {
    is: differentSalesRep => differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  salesforceEmail: yup.string().email(VALID_EMAIL_MESSAGE),
  overrideRepInfo: yup.string().when('overrideRep', {
    is: overrideRep => overrideRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  sstSalesforceEmail: yup.string().email(VALID_EMAIL_MESSAGE),
})

const AccountInformation = ({
  prevStep,
  nextStep,
  selectedCountry,
  industryCategoryType,
  industryTemplate,
  order,
  showBackButton = true,
}) => {
  const {state: locationState} = useLocation()
  const [emailRegistered, setEmailRegistered] = useState(true)
  const [showAccountExistsModal, setShowAccountExistsModal] = useState(false)
  const [showUpgradeAccountModal, setShowUpgradeAccountModal] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [listOfStates, setListOfStates] = useState([])
  const [existingThryvId, setExistingThryvId] = useState('')
  const [existingSubscriptionId, setExistingSubscriptionId] = useState('')
  const [validatedEmail, setValidatedEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isV5Upgrade, setIsV5Upgrade] = useState(false)
  const [thryvId, setThryvId] = useState('')
  const [isInvoiceBilled, setIsInvoiceBilled] = useState(false)
  const [showXpCode, setShowXpCode] = useState(false)
  const [showRepList, setShowRepList] = useState(false)
  const client = useAuthClient()
  const {roles} = useUserRoles()
  const {run} = useAsync({
    status: 'pending',
  })
  const navigate = useNavigate()
  const {user} = useAuth0()
  const {
    register,
    formState: {errors},
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      differentSalesRep: order.accountInformation?.behalf
        ? DIFFERENT_SALES_REP_OPTIONS[0]
        : order.accountInformation?.xpCode
        ? DIFFERENT_SALES_REP_OPTIONS[1]
        : DIFFERENT_SALES_REP_OPTIONS[2],
      overrideRep: OVERRIDE_SST_OPTIONS[1],
      email:
        locationState?.order.customerEmail ||
        order?.accountInformation?.email ||
        '',
      firstName:
        locationState?.order.customerFirstName ||
        order?.accountInformation?.firstName ||
        '',
      lastName:
        locationState?.order.customerLastName ||
        order?.accountInformation?.lastName ||
        '',
      xpCode:
        locationState?.order.xpCode || order?.accountInformation?.xpCode || '',
      company:
        locationState?.order.customerCompany ||
        order?.accountInformation?.company ||
        '',
      address:
        locationState?.order.customerAddress ||
        order?.accountInformation?.address ||
        '',
      city:
        locationState?.order.customerCity ||
        order?.accountInformation?.city ||
        '',
      postCode: formatPostalCode(
        locationState?.order.customerZip ||
          order?.accountInformation?.postCode ||
          '',
        selectedCountry,
      ),
      companyPhone: order?.accountInformation?.companyPhone || '',
      mobile: order?.accountInformation?.mobile || '',
      salesforceCustomerID:
        order?.accountInformation?.salesforceCustomerID || '',
      salesforceEmail: order?.accountInformation?.salesforceEmail || '',
      leadID: order?.accountInformation?.leadID || '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {componentProps: {selectedCountry}},
  })
  setValue(
    'leadIDRequired',
    roles.includes('PremisePilot') || roles.includes('LeadConverter'),
  )

  const isUS = selectedCountry === 'US'

  const [
    email,
    differentSalesRep,
    xpCode,
    salesRepCode,
    salesRepInfo,
    overrideRep,
    overrideRepCode,
    overrideRepInfo,
    state,
  ] = watch([
    'email',
    'differentSalesRep',
    'xpCode',
    'salesRepCode',
    'salesRepInfo',
    'overrideRep',
    'overrideRepCode',
    'overrideRepInfo',
    'state',
  ])

  const setData = formData => {
    let data = {
      ...formData,
      postCode: formData.postCode.replace(' ', ''),
      behalf: formData.salesRepCode,
      overrideRep: formData.overrideRepCode,
      xpCode: formData.xpCode,
      country: locationState?.order.selectedCountry.isoValue || selectedCountry,
      state: locationState?.order.customerState || formData.state.value,
      industryId: industryTemplate,
      software: industryCategoryType,
    }
    if ((salesRepCode || salesRepCode === '') && !differentSalesRep.value) {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalf
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
    }
    if ((overrideRepCode || overrideRepCode === '') && !overrideRep.value) {
      delete data.overrideRepInfo
      delete data.overrideRepCode
      delete data.overrideRep
      delete data.overrideRepEmail
      delete data.overrideRepFirst
      delete data.overrideRepLast
    }
    return data
  }

  const saveData = formData => {
    let data = {
      ...formData,
      industryCategoryType: industryCategoryType,
      behalf: formData.salesRepCode,
      overrideRepId: formData.overrideRepCode,
      xpCode: formData.xpCode,
      country: locationState?.order.selectedCountry.isoValue || selectedCountry,
      state: locationState?.order.customerState || formData.state.value,
      industryId: industryTemplate,
      software: industryCategoryType,
    }
    if ((salesRepCode || salesRepCode === '') && !differentSalesRep.value) {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalf
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
    }
    if ((overrideRepCode || overrideRepCode === '') && !overrideRep.value) {
      delete data.overrideRepInfo
      delete data.overrideRepCode
      delete data.overrideRep
      delete data.overrideRepEmail
      delete data.overrideRepFirst
      delete data.overrideRepLast
    }
    if (differentSalesRep.value !== false) {
      delete data.xpCode
    }
    return data
  }

  const submitForm = async formData => {
    const data = saveData(formData)
    try {
      await run(client('orders/', {data}))
      toast.success('Form submitted successfully')
      setTimeout(() => {
        navigate('/orders')
      }, 5000)
    } catch (responseError) {
      if (responseError.status !== 400) {
        throw responseError
      }
      responseError.errors.forEach(({message}) => toast.error(message))
    }
  }

  const handleNextStepData = async formData => {
    try {
      const accountInformationData = setData(formData)
      let nextStepData = {accountInformation: accountInformationData}

      const data = {
        email: accountInformationData.email,
        salesforceEmail: accountInformationData.salesforceEmail,
        firstName: accountInformationData.firstName,
        lastName: accountInformationData.lastName,
        city: accountInformationData.city,
        state: accountInformationData.state,
        country: accountInformationData.country,
        phone: accountInformationData.companyPhone,
        industryId: accountInformationData.industryId,
        mobile: accountInformationData.mobile,
        address: accountInformationData.address,
        company: accountInformationData.company,
        zipCode: accountInformationData.postCode,
        onBehalf: accountInformationData.behalf,
        xpCode: accountInformationData.xpCode,
      }

      if (formData.differentSalesRep.value === 'true') {
        delete data.xpCode
      } else if (
        formData.differentSalesRep.value === 'false' ||
        formData.differentSalesRep.value === 'No'
      ) {
        delete data.onBehalf
        delete data.xpCode
      }

      if (!data.salesforceEmail) delete data.salesforceEmail
      setIsLoading(true)
      const response = await run(client('accounts/5', {data, method: 'POST'}))
      setIsLoading(false)
      nextStepData = {...nextStepData, CCAccountInfo: response}
      nextStep(nextStepData)
    } catch (err) {
      setIsLoading(false)
      toast.error('Error creating account.')
    }
  }

  React.useEffect(() => {
    const states = rawCountryStates[selectedCountry].regions
      .map(({iso, name}) => ({
        name,
        value: iso || name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setListOfStates(states)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const shouldDisableSubmitButton = (repInfo, rep) => {
      if (
        rep.value === true &&
        (repInfo === SALES_REP_INFO_NOT_FOUND || !repInfo)
      ) {
        return true
      }
      return false
    }
    if (
      shouldDisableSubmitButton(salesRepInfo, differentSalesRep) ||
      shouldDisableSubmitButton(overrideRepInfo, overrideRep)
    ) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }
  }, [salesRepInfo, differentSalesRep, overrideRepInfo, overrideRep])

  React.useEffect(() => {
    if (order.savedOrder) {
      setValue('email', order.accountInformation.email)
      setValue('mobile', order.accountInformation.mobile)
      setValue('firstName', order.accountInformation.firstName)
      setValue('lastName', order.accountInformation.lastName)
      setValue('company', order.accountInformation.company)
      setValue('address', order.accountInformation.address)
      setValue('city', order.accountInformation.city)
      setValue('postCode', order.accountInformation.postCode)
      setValue('companyPhone', order.accountInformation.companyPhone)
      setValue(
        'state',
        listOfStates.find(x => x.value === order.accountInformation.state),
      )
    }
    if (order.fromDemo) {
      setValue('firstName', order.customerFirstName)
      setValue('lastName', order.customerLastName)
      setValue('company', order.customerCompany)
      setValue('email', order.customerEmail)
      setValue('mobile', order.customerPhone)
      setValue('address', order.customerAddress)
      setValue('city', order.customerCity)
      setValue('postCode', order.customerZip)
      setValue(
        'state',
        listOfStates.find(x => x.value === order.customerState),
      )
    }
  }, [order, setValue, listOfStates, user, differentSalesRep])

  React.useEffect(() => {
    if (differentSalesRep.value) {
      setShowRepList(true)
      setShowXpCode(false)
    }
    if (!differentSalesRep.value) {
      setShowRepList(false)
      setShowXpCode(true)
    }
    if (differentSalesRep.value === 'No') {
      setShowXpCode(false)
      setShowRepList(false)
    }
    if (!differentSalesRep.value && xpCode !== null && xpCode !== '') {
      setShowXpCode(true)
    }
  }, [differentSalesRep, xpCode])

  React.useEffect(() => {
    if (!order?.accountInformation?.state) return
    setValue(
      'state',
      listOfStates.find(x => x.value === order.accountInformation.state),
    )
  }, [listOfStates, order?.accountInformation?.state, setValue])

  React.useEffect(() => {
    if (!order?.accountInformation?.salesRepName) return
    // setValue('salesRepCode', order.accountInformation.salesRepCode)
    setValue('salesRepInfo', order.accountInformation.salesRepName)
    // setValue('differentSalesRep', order.accountInformation.differentSalesRep)
  }, [
    order?.accountInformation?.differentSalesRep,
    order?.accountInformation?.salesRepCode,
    order?.accountInformation?.salesRepName,
    setValue,
  ])

  React.useEffect(() => {
    if (!order?.accountInformation?.overrideRepCode) return
    setValue('overrideRepCode', order.accountInformation.overrideRepCode)
    setValue('overrideRepInfo', order.accountInformation.overrideRepInfo)
    setValue('overrideRep', order.accountInformation.overrideRep)
  }, [
    order?.accountInformation?.overrideRep,
    order?.accountInformation?.overrideRepCode,
    order?.accountInformation?.overrideRepInfo,
    setValue,
  ])

  return (
    <>
      {isLoading && <LoadingTimelineModal />}
      {showAccountExistsModal && (
        <Modal
          altBtn={<></>}
          openOnLoad
          variant="default"
          footer
          action
          btnAction={() => setShowAccountExistsModal(false)}
          onClose={() => setShowAccountExistsModal(false)}
          btnActionText="Ok"
          hideX
        >
          An account with this email already exists. <br />
          Please enter a different email address.
        </Modal>
      )}
      {showUpgradeAccountModal && (
        <Modal
          altBtn={<></>}
          openOnLoad
          variant="lg"
          footer
          action
          btnAction={() => {
            navigate('/order/upgrade', {
              state: {
                order: {
                  billingAccountId: existingSubscriptionId,
                  subscriptionId: existingSubscriptionId,
                  thryvId: existingThryvId,
                  v5upgrade: isV5Upgrade,
                  ...order,
                  ...(isV5Upgrade && {
                    CCAccountInfo: {thryvId: existingThryvId},
                  }),
                },
                currentStep: 1,
              },
            })
          }}
          onClose={() => setShowUpgradeAccountModal(false)}
          btnActionText="Update"
          hideX
          footerCancel
        >
          <div className="text-center">
            <ModalTitle variant="primary">
              An account with this email already exists
            </ModalTitle>
            <ParagraphText variant="reg" className="mt-1">
              Do you want to update this Thryv?
            </ParagraphText>
          </div>
        </Modal>
      )}
      <NewOrderLayout
        prevStep={prevStep}
        showContinueButton={false}
        title={!isInvoiceBilled ? 'Account Information' : 'Invoices'}
        style={isLoading && {pointerEvents: 'none'}}
        showBackButton={showBackButton}
      >
        {isInvoiceBilled ? (
          <InvoiceInformation
            thryvId={thryvId}
            email={email}
            setIsInvoiceBilled={setIsInvoiceBilled}
          />
        ) : (
          <AccountInformationForm
            register={register}
            errors={errors}
            listOfStates={listOfStates}
            selectedCountry={selectedCountry}
            salesRepInfo={salesRepInfo}
            overrideRepInfo={overrideRepInfo}
            differentSalesRep={differentSalesRep}
            overrideRep={overrideRep}
            validatedEmail={validatedEmail}
            email={email}
            xpCode={xpCode}
            emailRegistered={emailRegistered}
            setEmailRegistered={setEmailRegistered}
            setValidatedEmail={setValidatedEmail}
            setExistingThryvId={setExistingThryvId}
            setExistingSubscriptionId={setExistingSubscriptionId}
            setIsV5Upgrade={setIsV5Upgrade}
            setShowUpgradeAccountModal={setShowUpgradeAccountModal}
            setShowAccountExistsModal={setShowAccountExistsModal}
            setIsInvoiceBilled={setIsInvoiceBilled}
            setValue={setValue}
            salesRepCode={salesRepCode}
            overrideRepCode={overrideRepCode}
            trigger={trigger}
            isUS={isUS}
            roles={roles}
            control={control}
            state={state}
            order={order}
            user={user}
            setThryvId={setThryvId}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            handleSubmit={handleSubmit}
            submitForm={submitForm}
            handleNextStepData={handleNextStepData}
            showXpCode={showXpCode}
            setShowXpCode={setShowXpCode}
            showRepList={showRepList}
            setShowRepList={setShowRepList}
          />
        )}
      </NewOrderLayout>
    </>
  )
}

export {AccountInformation}
