import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useRef,
} from 'react'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import {useAuthClient} from '../utils/use-auth-client'
import {Input, Button, ParagraphText, Icon, Timeline} from '@thryvlabs/maverick'
import {toast} from 'react-toastify'
import {BILLING_PREFERENCES} from '../utils/constants'
import {NewOrderLayout} from '../components/new-order-layout'
import {CartPaymentDetails} from '../components/cart-payment-details'
import {PhoneNumberUtil} from 'google-libphonenumber'
import {useLocation} from 'react-router'
import {headerContext} from '../context/header-context'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {
  CouponContainer,
  SendPaymentLinkText,
  TimelineContainer,
  BillingPromptContainer,
} from '../components/ui/payment-details'
import CountdownTimer from '../components/countdown-timer'
import dayjs from 'dayjs'
import io from 'socket.io-client'
import {getUpgradeIncentivePlans} from '../utils/incentives'
import {TransitionContainer} from '../components/ui/cartProducts'
import {Spinner} from '../components/loader-spinner'
import {PlanComparisonDetails} from '../components/plan-comparison-details'
import {useNavigate} from 'react-router-dom'
import {CcSidebar} from '../components/cc-sidebar'
import {ADD_ON} from '../utils/addons-data'
import {useAsync} from '../utils/use-async'
import PaymentLink from '../components/payment-link'
// import {CC_PLANS, TRANSITION_INCENTIVES} from '../utils/constants'
import {BillingOptionsModal} from '../components/billing-options-modal'
import getPreviewItemTotal from '../utils/get-preview-item-total'
import {formatPrice} from '../utils/currency-format'

const autoPayTimeline = [
  {
    eventTitle: <p>Payment Link Sent</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Client Clicked Link</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Payment Method Added</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Proceed with Order</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
]

const invoiceTimeline = [
  {
    eventTitle: <p>Invoice Link Sent</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Client Clicked Link</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Billing Address Updated</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
  {
    eventTitle: <p>Proceed with Order</p>,
    eventSubtitle: '',
    theme: 'neutral',
  },
]

const phoneUtil = PhoneNumberUtil.getInstance()

const {REACT_APP_OOE_SERVICE_URL} = process.env

const SCHEMA = yup.object().shape({
  smsContact: yup
    .string()
    .required('This field is required')
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.options.context.componentProps.countryIsoValue
          value = value.replace(/[^\d]/g, '')
          const parsedPhone = phoneUtil.parse(value, country)
          return phoneUtil.isValidNumber(parsedPhone)
        } catch {
          return false
        }
      },
    ),
})

const PaymentDetails = ({
  nextStep,
  prevStep,
  selectedAddons,
  sendPaymentLink,
  resendPaymentLink,
  checkClientEmailStatus,
  showSmsOption,
  setShwoSmsOption,
  accountInformation,
  countryIsoValue,
  selectedCountry,
  kickoffApptId,
  creativeApptId,
  setCreativeApptId,
  setKickoffApptId,
  setOrderCouponCodes,
  orderCouponCodes,
  accountInfo,
  selectedPlan,
  cartProducts,
  setCartProducts,
  currentStep,
  processing,
  setProcessing,
  setCreativeCallDate,
  setOnboardingCallDate,
  clientEmail,
  onboardingCallDate,
  creativeCallDate,
  selectedCentersAndApps,
  sendCCPaymentLink,
  ccCouponInfo,
  isCCPlan,
  defaultCentersAndApps,
  incentiveCoupon,
  isSEOIncluded,
  autoCouponInfo,
  upgradePaymentPreview,
  allowInvoice,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  previewRequestFinished,
  isCohort,
  cohortEaid,
  orderData5CU,
  orderData4CU,
  order5NUId,
  isCancelOrder5,
  cancelOrder5,
  updateApptOnExpire,
  captureOrder4NewUpgrade,
}) => {
  const {pathname} = useLocation()
  const [contactMethod, setContactMethod] = useState('')
  const [couponCode, setCouponCode] = useState('')
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false)
  const [couponInfo, setCouponInfo] = useState({})
  const [autoCouponDiscount, setAutoCouponDiscount] = useState(0)
  const [hideCouponInput, setHideCouponInput] = useState(false)
  const [monthlyBilling, setMonthlyBilling] = useState(false)
  const [monthlyTotal, setMonthlyTotal] = useState()
  const [semiAnnuallyBilling, setSemiAnnuallyBilling] = useState(false)
  const [semiAnnuallyTotal, setSemiAnnuallyTotal] = useState()
  const [monthlyPayDate, setMonthlyPayDate] = useState()
  const [semiAnnuallyPayDate, setSemiAnnuallyPayDate] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [currentPrice, setCurrentPrice] = useState(0)
  const [totalCcPrice, setTotalCcPrice] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  // const [discountArray] = useState([])
  const [uuid, setUuid] = useState()
  const [shortenedUrl, setShortenedUrl] = useState(null)
  const [orderId, setOrderId] = useState()
  const isNewOrder = pathname === '/order/new'
  const [orderCompleted, setOrderCompleted] = useState(false)
  const [processingMessage, setProcessingMessage] = useState(false)
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [sendLinkError, setSendLinkError] = useState(false)
  const [ccTotal, setCcTotal] = useState(0)
  const [nextAmountDue, setNextAmountDue] = useState(0)
  const [softwareUpgraded, setSoftwareUpgraded] = useState(false)
  const [bypassSms, setBypassSms] = useState(false)
  const [showEmailStatusLinkOption, setShowEmailStatusLinkOption] =
    useState(false)
  const TIMESTAMP_60_MINUTES_FROM_NOW = useRef(dayjs() + 3601000)
  const {isHeaderVisible, setIsHeaderVisible} = useContext(headerContext)
  const client = useAuthClient()
  const navigate = useNavigate()
  const {frontendSideNav, frontendCohortRelease} = useFlags()
  const {handleSubmit} = useForm({
    mode: 'onSubmit',
    defaultValues: {
      smsContact: accountInformation.mobile,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {componentProps: {countryIsoValue}},
  })
  const {run} = useAsync({
    status: 'pending',
  })

  const transitionOrder = autoCouponInfo.some(
    ({description}) =>
      description === 'Transition Incentive' ||
      description === 'Transition Coupon',
  )
  const eligibleTransitionCenters =
    !isNewOrder &&
    selectedCentersAndApps &&
    getUpgradeIncentivePlans(
      selectedCentersAndApps.centers,
      defaultCentersAndApps.centers,
      upgradePaymentPreview,
    )
  const stringifiedEligibleTransitionCenters = JSON.stringify(
    eligibleTransitionCenters,
  )
  const isAus = countryIsoValue === 'AU'
  const isNZ = countryIsoValue === 'NZ'

  const handleCouponCodeChange = e => {
    const {value} = e.target
    setCouponCode(value)
  }
  const addonsPrice =
    Number(cartProducts?.find(x => x.product === 'Addons')?.price?.slice(1)) ||
    0
  const monthlyPrice =
    addonsPrice +
    Number(cartProducts[0]?.price.slice(1).replace(/[$,a-zA-Z]/g, ''))
  const mcInAddonsPrice = selectedAddons?.find(x =>
    ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(x.planCode),
  )
  const incentiveEligibleCart =
    (selectedPlan?.name?.includes('sync')
      ? 0
      : Number(cartProducts[0]?.price.replace(/[$,a-zA-Z]/g, ''))) +
    (Number(mcInAddonsPrice?.price.replace(/[$,a-zA-Z]/g, '')) || 0)

  const validateCoupon = async () => {
    const addonsString = selectedAddons
      .filter(addon => addon.planCode !== undefined)
      .map(addon => addon.planCode)
    if (couponCode && couponInfo?.code !== couponCode) {
      setCouponCode(couponCode.trim())
      try {
        let url = `orders/coupon/validate?couponCode=${couponCode}&country=${countryIsoValue}&planCode=${accountInformation.package}`
        if (addonsString.length > 0) {
          url = `${url}&addonsString=${addonsString}`
        }
        const response = await client(url)
        setIsCouponCodeValid(response.isValid)
        setCouponInfo({...response, code: couponCode.trim()})
        const discountArray = []
        discountArray.push(response)
        captureOrderDiscounts(discountArray)
        if (!response.isValid) {
          toast.error('Invalid coupon.')
        }
      } catch (error) {
        if (error.status === 404) {
          toast.error('Invalid coupon.')
          setIsCouponCodeValid(false)
          setCouponInfo({isValid: false})
        }
      }
    }
  }

  const captureOrderDiscounts = async discountData => {
    let allDiscount = []
    let lineCounter = 1
    for (let data of discountData) {
      const dataObj = {
        order_id: order5NUId,
        line_number: lineCounter,
        discount_type: data.type,
        coupon_code: data.code,
        discount_percentage: data.discountPercent,
        discount_amount: data.discountAmount,
        number_of_months: data.durationAmount + data.durationUnit,
        start_date: new Date(),
        end_date: datePlusDuration(
          new Date(),
          data.durationAmount,
          data.durationUnit,
        ),
        created_date: '2024-08-19T18:30:00.000Z',
        create_oprid: null,
        updated_date: '2024-08-19T18:30:00.000Z',
        update_oprid: null,
      }
      allDiscount.push(dataObj)
      lineCounter = lineCounter + 1
    }
    console.log('all Discounts', allDiscount)
    captureOrder4NewUpgrade('apply-coupon', 'new-upgrade', allDiscount)
  }

  function datePlusDuration(date, duration, unit) {
    const newDate = new Date(date)
    if (unit === 'month') newDate.setMonth(newDate.getMonth() + duration)
    if (unit === 'day') newDate.setDate(newDate.getDate() + duration)
    if (unit === 'year') newDate.setFullYear(newDate.getFullYear() + duration)
    return newDate
  }

  const getTotalPrice = products => {
    let totalPrice = 0
    for (const i in products) {
      if (products[i].code !== '247support-m2m') {
        if (!isNewOrder && products[i].billingPreference === 'oneTime') {
          continue
        }
        if (typeof products[i].price === 'string') {
          totalPrice +=
            parseFloat(products[i].price.replace(/[$,a-zA-Z]/g, '')).toFixed(
              2,
            ) * 1
        } else {
          totalPrice += products[i].price
        }
      }
    }
    if (accountInfo?.balanceInfo?.availableCreditAmount) {
      totalPrice = totalPrice + accountInfo?.balanceInfo?.availableCreditAmount
    }
    return totalPrice > 0 ? totalPrice : 0
  }
  useEffect(() => {
    if (selectedCentersAndApps) return
    let currentTotal = getTotalPrice(selectedAddons)

    let newAutoCouponDiscount = 0
    let newDiscountAmount = 0
    autoCouponInfo.forEach(coupon => {
      if (coupon.discountAmount) {
        newAutoCouponDiscount += coupon.discountAmount
      } else if (coupon.discountPercent && coupon.appliesToAllPlans) {
        selectedAddons.forEach(addon => {
          if (!addon.planCode.includes('setupfee')) {
            newAutoCouponDiscount +=
              Number(addon.price.replace(/[^0-9.]/g, '')) *
              (coupon.discountPercent / 100)
          }
        })
      } else {
        selectedAddons.forEach(addon => {
          if (
            coupon?.appliesToPlans?.includes(addon.planCode) &&
            !addon.planCode.includes('setupfee')
          ) {
            newAutoCouponDiscount +=
              Number(addon.price.replace(/[^0-9.]/g, '')) *
              (coupon.discountPercent / 100)
          }
        })
      }
    })
    if (!couponInfo.isValid) {
      newDiscountAmount = 0
    } else if (couponInfo.discountAmount) {
      newDiscountAmount += couponInfo.discountAmount
    } else if (couponInfo.discountPercent && couponInfo.appliesToAllPlans) {
      selectedAddons.forEach(addon => {
        if (!addon.planCode.includes('setupfee')) {
          newDiscountAmount +=
            Number(addon.price.replace(/[^0-9.]/g, '')) *
            (couponInfo.discountPercent / 100)
        }
      })
      currentTotal -= newDiscountAmount
    } else {
      selectedAddons.forEach(addon => {
        if (
          couponInfo?.appliesToPlans?.includes(addon.planCode) &&
          !addon.planCode.includes('setupfee')
        ) {
          newDiscountAmount +=
            Number(addon.price.replace(/[^0-9.]/g, '')) *
            (couponInfo.discountPercent / 100)
        }
      })
    }
    setOrderCouponCodes(
      [...autoCouponInfo, isCouponCodeValid ? couponInfo : null].filter(
        Boolean,
      ),
    )
    const newTotalPrice = parseFloat(
      Math.max(currentTotal - newDiscountAmount - newAutoCouponDiscount, 0),
    )

    setAutoCouponDiscount(newAutoCouponDiscount)
    setDiscountAmount(newDiscountAmount)
    setTotalPrice(newTotalPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    client,
    countryIsoValue,
    couponInfo,
    couponInfo.discountAmount,
    couponInfo.discountPercent,
    couponInfo.isPercentDiscount,
    couponInfo.isValid,
    discountAmount,
    isCouponCodeValid,
    selectedAddons,
    setOrderCouponCodes,
    selectedCentersAndApps,
  ])

  const getUuid = clientInformation => {
    if (isNewOrder && !selectedCentersAndApps) {
      return clientInformation.uuid
    } else if (selectedCentersAndApps) {
      return clientInformation.paymentUuid
    } else {
      return clientInformation.randomId
    }
  }

  const handleSendLink = async data => {
    setProcessingMessage('Sending Link...')
    setProcessing(true)
    let clientInformation
    if (selectedCentersAndApps) {
      clientInformation = await sendCCPaymentLink(
        bypassSms ? 'tss' : contactMethod,
        accountInformation.mobile,
      )
    } else {
      const contactInfo =
        contactMethod === 'sms' ? data.smsContact : clientEmail
      clientInformation = await sendPaymentLink(contactMethod, contactInfo)
    }
    setProcessing(false)
    if (clientInformation) {
      const uuid = getUuid(clientInformation)
      setSendLinkError(false)
      setOrderId(clientInformation.orderId)
      updateTimeline(0)
      setShortenedUrl(clientInformation.shortenedUrl)
      if (bypassSms) {
        let data
        if (selectedCentersAndApps) {
          data = {
            orderUuid: uuid,
            billingMethod: 'TSS',
          }
        } else {
          data = {
            orderId: clientInformation.orderId.toString(),
            billingInfo: {
              firstName: accountInfo.recurlyFirst,
              lastName: accountInfo.recurlyLast,
              address: {
                street1: accountInfo.billingAddress.street1,
                city: accountInfo.billingAddress.city,
                state: accountInfo.billingAddress.region,
                country: accountInfo.billingAddress.country,
                postalCode: accountInfo.billingAddress.postalCode,
              },
            },
          }
        }
        try {
          await run(
            client(
              selectedCentersAndApps
                ? 'cc/order/save-billing'
                : 'orders/process/upgrade/billing-tss',
              {data, method: selectedCentersAndApps ? 'POST' : 'PUT'},
            ),
          )
          setOrderCompleted(true)
        } catch (err) {
          toast.error(err.message)
        }
      } else {
        setUuid(uuid)
        setIsLinkSent(true)
        setHideCouponInput(true)
      }
    } else {
      setSendLinkError(true)
      setShortenedUrl(null)
    }
  }
  const handleResendLink = async data => {
    resetTimeline()
    const resendInfo = {
      orderId,
      mobile: selectedCentersAndApps
        ? accountInformation.mobile
        : data.smsContact,
      method: contactMethod,
      email: clientEmail,
    }
    setProcessingMessage('Resending Link...')
    setProcessing(true)
    const {updatedUuid, shortenedUrl} = await resendPaymentLink(resendInfo)
    setProcessing(false)
    if (updatedUuid) {
      setIsLinkSent(true)
      setSendLinkError(false)
      // 4.0 flow -> setUuid(updatedUuid.randomId || updatedUuid.uuid)
      setUuid(updatedUuid)
      updateTimeline(0)
      setShortenedUrl(shortenedUrl)
      setTimeout(() => {
        setShowEmailStatusLinkOption(true)
      }, 60000)
    } else {
      setSendLinkError(true)
      setShortenedUrl(null)
    }
  }
  const handleCheckSendGridData = async () => {
    setProcessingMessage('Checking Email Status...')
    setProcessing(true)

    const clientInfo = {
      email: clientEmail,
    }

    await checkClientEmailStatus(clientInfo)

    setProcessing(false)
  }
  const [timelineEvents, setTimelineEvents] = React.useState(autoPayTimeline)

  useEffect(() => {
    if (selectedPaymentMethod === 'Credit Card') {
      setTimelineEvents(autoPayTimeline)
    } else {
      setTimelineEvents(invoiceTimeline)
    }
  }, [selectedPaymentMethod])

  useEffect(() => {
    if (selectedCentersAndApps) return
    setMonthlyTotal(
      parseFloat(
        getTotalPrice(
          selectedAddons.filter(
            ({billingPreference}) =>
              billingPreference === BILLING_PREFERENCES[0],
          ),
        ),
      ),
    )

    setSemiAnnuallyTotal(
      parseFloat(
        getTotalPrice(
          selectedAddons.filter(
            ({billingPreference}) =>
              billingPreference === BILLING_PREFERENCES[1],
          ),
        ),
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddons])
  useEffect(() => {
    if (parseFloat(semiAnnuallyTotal) > 0) {
      setSemiAnnuallyBilling(true)
    }
    const nextSemiAnnualPayDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 6,
      new Date().getDate(),
    )
    const day = nextSemiAnnualPayDay.getDate()
    const month = nextSemiAnnualPayDay.toLocaleString('default', {
      month: 'long',
    })
    const year = nextSemiAnnualPayDay.getFullYear()
    setSemiAnnuallyPayDate(`${month},${day} ${year}`)
  }, [semiAnnuallyTotal])
  useEffect(() => {
    if (parseFloat(monthlyTotal) > 0) {
      setMonthlyBilling(true)
    }
    const nextMonthlyPayDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate(),
    )
    const day = nextMonthlyPayDay.getDate()
    const month = nextMonthlyPayDay.toLocaleString('default', {month: 'long'})
    const year = nextMonthlyPayDay.getFullYear()
    setMonthlyPayDate(`${month} ${day}, ${year}`)
  }, [monthlyTotal])

  const updateTimeline = useCallback(step => {
    setTimelineEvents(prevTimelineEvents => {
      const copyOfTimeline = prevTimelineEvents.map(event => ({...event}))
      copyOfTimeline[step].eventSubtitle = dayjs().format('MMMM D [at] h:mm A')
      copyOfTimeline[step].theme = 'primary'
      return copyOfTimeline
    })
    if (step === 3) {
      setOrderCompleted(true)
    }
  }, [])

  const resetTimeline = () => {
    setTimelineEvents(prevTimelineEvents => {
      const copyOfTimeline = prevTimelineEvents.map(event => ({...event}))
      copyOfTimeline.forEach(step => {
        step.eventSubtitle = ''
        step.theme = 'neutral'
      })
      return copyOfTimeline
    })
  }

  const processPurchase = async () => {
    if (!orderCompleted) return
    try {
      setProcessingMessage('Processing Purchase...')
      setProcessing(true)
      const processResponse = await client(
        isNewOrder
          ? 'orders/process/purchase'
          : 'orders/process/upgrade/purchase',
        {
          data: {
            orderId: orderId?.toString(),
          },
        },
      )
      if (processResponse.errors && processResponse.errors.length > 0) {
        processResponse.errors.forEach(({message}) => toast.error(message))
      } else {
        captureCompleteOrder4()
      }
      setProcessing(false)
      nextStep()
    } catch (responseError) {
      setProcessing(false)
      if (responseError.status === 404) {
        toast.error(responseError.message)
        throw responseError
      }
      if (responseError.status !== 400) {
        toast.error(responseError.message)
        throw responseError
      }
      if (responseError.message) {
        toast.error(responseError.message)
      } else {
        responseError.errors.forEach(({message}) => toast.error(message))
      }
    }
  }

  const processPurchaseCC = async () => {
    if (!orderCompleted) return
    try {
      setProcessingMessage('Processing Purchase...')
      setProcessing(true)
      const processResponse = await client('cc/order/process-purchase', {
        data: {
          orderId: orderId?.toString(),
        },
      })
      if (processResponse.errors && processResponse.errors.length > 0) {
        processResponse.errors.forEach(({message}) => toast.error(message))
      } else {
        captureCompleteOrder5()
      }
      setProcessing(false)
      navigate('/success', {
        state: {
          email: clientEmail,
          flow: isNewOrder ? 'new order' : 'upgrade',
          onboardingCallDate,
          creativeCallDate,
          isCC: true,
          isSEOIncluded: isSEOIncluded,
          softwareUpgraded: softwareUpgraded,
        },
      })
    } catch (responseError) {
      setProcessing(false)
      if (responseError.status === 404) {
        toast.error(responseError.message)
        throw responseError
      }
      if (responseError.status !== 400) {
        toast.error(responseError.message)
        throw responseError
      }
      if (responseError.message) {
        toast.error(responseError.message)
      } else {
        responseError.errors.forEach(({message}) => toast.error(message))
      }
    }
  }

  const captureCompleteOrder5 = async () => {
    const fetchAccountSubscriptions = async (retriesAmount = 3) => {
      try {
        const url = `accounts/5/subscriptions?thryvId=${orderData5CU.order.CCAccountInfo.thryvId}`
        const response = await client(url)
        if (
          !response.childSubscriptions.find(
            sub => sub.code === 'appmarket-5-thryv-m2m',
          ) ||
          !response.parentSubscriptions.find(sub => sub.code.includes('cc_'))
        ) {
          throw Error('missing subscription')
        } else {
          getOrder5AndComplete(response)
        }
      } catch (err) {
        if (
          [404, 500].includes(err.status) ||
          err.message === 'missing subscription'
        ) {
          if (retriesAmount === 0) throw err
          setTimeout(() => fetchAccountSubscriptions(retriesAmount - 1), 1000)
        }
      }
    }
    fetchAccountSubscriptions()
  }

  const captureCompleteOrder4 = async () => {
    const fetchAccountSubscriptions = async () => {
      try {
        let url = `subscription/uuid-${orderData4CU.order.billingAccountId}`
        const response = await client(url)
        if (response.thryvID) {
          getOrder5AndComplete(response)
        }
      } catch (err) {
        if (err.status === 404) {
          console.log(err)
        }
        if (err.status !== 404) {
          console.log(err)
        }
      }
    }
    fetchAccountSubscriptions()
  }

  const getOrder5AndComplete = async subscriptions => {
    try {
      let subscriptionLines = []
      if (isCCPlan) {
        subscriptionLines = [
          ...subscriptions.parentSubscriptions,
          ...subscriptions.childSubscriptions,
        ]
      } else {
        subscriptionLines = [...subscriptions.addOns]
      }
      console.log('subscriptionLines', subscriptionLines)
      const url = `ordercapture/services/getOrderDetails/${order5NUId}`
      const response = await client(url)
      if (response.success) {
        console.log('get order header lines and discounts', response)
        console.log('get subscriptionLines', subscriptionLines)
        const order5Data = response.response
        if (order5Data) {
          order5Data.lines?.forEach(oLine => {
            subscriptionLines.forEach(subLine => {
              if (subLine.code === oLine.saas_product_code) {
                oLine.recurly_subscription_id = subLine.id
              } else {
                if (subLine && subLine.addons && subLine.addons.length > 0) {
                  const addonLine = subLine.addons.find(
                    line => line.code === oLine.saas_product_code,
                  )
                  console.log('addonLine', addonLine)
                  oLine.recurly_subscription_id = addonLine.id
                }
              }
            })
            oLine.order_line_status = 'Completed'
          })
          order5Data.order_status = 'Completed'
          order5Data.order_completed_date = new Date()
          order5Data.order_version =
            order5Data.order_version === 5 ? '5.0' : '4.0'
          completeOrder5(order5Data)
        }
      }
      console.log('get order - ', response)
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error complete order: ${error.message}`)
      }
      if (error.errors) {
        // error.errors.forEach(({message}) => toast.error(message))
        error.errors.forEach(({message}) => console.log(message))
      } else {
        console.log(`Error complete order: ${error.message}`)
      }
    }
  }

  const completeOrder5 = async order5Data => {
    try {
      console.log('order complete Data - new-upgrade', order5Data)
      const result = await run(
        client('ordercapture/services/updateOrder', {
          data: order5Data,
          method: 'POST',
        }),
      )
      console.log('order Completed - new-upgrade', result)
    } catch (error) {
      if (error.status !== 400) {
        console.log(
          `Error complete order - captureCompleteOrder5: ${error.message}`,
        )
      }
      if (error.errors) {
        // error.errors.forEach(({message}) => toast.error(message))
        error.errors.forEach(({message}) => console.log(message))
      } else {
        console.log(
          `Error complete order - captureCompleteOrder5: ${error.message}`,
        )
      }
    }
  }

  useEffect(() => {
    const socket = io(REACT_APP_OOE_SERVICE_URL, {
      transports: ['websocket'],
    })
    socket.on('connect', () => {})
    if (uuid) {
      socket.emit('join', {uuid}, () => {})
      socket.on('stepTaken', ({stepNumber}) => {
        updateTimeline(stepNumber)
      })
    }
    return () => {
      socket.close()
    }
  }, [uuid, updateTimeline])

  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      setIsHeaderVisible(true)
    })
    setIsHeaderVisible(false)
    return () => {
      window.removeEventListener('popstate', function (event) {
        setIsHeaderVisible(true)
      })
    }
  }, [setIsHeaderVisible])

  useEffect(() => {
    if (!selectedCentersAndApps) return
    const {apps, centers} = selectedCentersAndApps

    const calcTotal = ({arr, discountAmount}) => {
      return arr.reduce((acc, {name, amount, quantity, code}) => {
        quantity =
          name === 'Thryv Leads' ||
          (name.includes('SEO') &&
            name !== 'SEO' &&
            name !== 'SEO Keywords' &&
            name !== 'SEO PowerBoost')
            ? 1
            : quantity
        let price = amount * quantity
        // const transitionApplies = (
        //   isNewOrder ? CC_PLANS : eligibleTransitionCenters
        // ).some(plan => name.toLowerCase().includes(plan))

        if (upgradePaymentPreview) {
          const previewAmount = getPreviewItemTotal({
            upgradePaymentPreview,
            item: {name, price},
            country: countryIsoValue,
          })
          if (previewAmount) price = previewAmount
        }

        if (!price) return acc

        // if (
        //   transitionApplies &&
        //   discountAmount &&
        //   discountType === 'New Client Transition'
        // ) {
        //   price = price - price * (discountAmount / 100)
        // }
        if (discountAmount) {
          price = price - price * (discountAmount / 100)
        }

        if (
          ccCouponInfo?.appliesToPlans?.includes(code) &&
          ccCouponInfo?.duration === 'forever'
        ) {
          const discountAmount = ccCouponInfo?.discountAmount
          if (discountAmount && price < discountAmount) {
            price -= ccCouponInfo.discountAmount
          }
        }

        return acc + price
      }, 0)
    }

    const appsTotal = calcTotal({arr: apps})
    const centersTotal = calcTotal({arr: centers})
    const nextMonthAmount = calcTotal({
      arr: centers,
      discountAmount: 0,
    })

    setCcTotal(centersTotal + appsTotal)
    setNextAmountDue(nextMonthAmount + appsTotal)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    ccCouponInfo,
    selectedCentersAndApps,
    setOrderCouponCodes,
    isNewOrder,
    stringifiedEligibleTransitionCenters,
    upgradePaymentPreview,
  ])

  useEffect(() => {
    function haveNewEntitlementsBeenAdded(selectedCenter, defaultCenter) {
      if (!selectedCenter || selectedCenter.entitlements.length === 0) {
        return false
      }
      if (
        (!defaultCenter || defaultCenter.entitlements.length === 0) &&
        selectedCenter.entitlements.length > 0
      ) {
        return true
      }
      if (defaultCenter && defaultCenter.entitlements.length > 0) {
        return selectedCenter.entitlements.some(
          selectedEntitlement =>
            !defaultCenter.entitlements.some(
              defaultEntitlement =>
                defaultEntitlement.name === selectedEntitlement.name,
            ),
        )
      }
      return false
    }
    if (!isNewOrder && selectedCentersAndApps?.centers.length > 1) {
      const defaultBusinessCenter = defaultCentersAndApps.centers.find(
        ({name}) => name.includes('Business'),
      )
      const selectedBusinessCenter = selectedCentersAndApps.centers.find(
        ({name}) => name.includes('Business'),
      )
      const defaultMarketingCenterPro = defaultCentersAndApps.centers.find(
        ({name}) => name === 'Marketing Center Pro',
      )
      const selectedMarketingCenterPro = selectedCentersAndApps.centers.find(
        ({name}) => name === 'Marketing Center Pro',
      )
      const centersUpgraded = selectedCentersAndApps.centers.some(
        ({name, alreadyIncluded}) =>
          (name.includes('Marketing') || name.includes('Business')) &&
          !alreadyIncluded,
      )
      if (
        haveNewEntitlementsBeenAdded(
          selectedBusinessCenter,
          defaultBusinessCenter,
        ) ||
        haveNewEntitlementsBeenAdded(
          selectedMarketingCenterPro,
          defaultMarketingCenterPro,
        ) ||
        centersUpgraded
      ) {
        setSoftwareUpgraded(true)
      }
    }
  }, [
    isNewOrder,
    defaultCentersAndApps?.centers,
    selectedCentersAndApps?.centers,
  ])

  useEffect(() => {
    if (!frontendSideNav) return

    let elem = document.getElementById('app-container')

    elem.style.gridTemplateAreas =
      '"main-content main-content" "main-content main-content" "sidebar footer"'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <NewOrderLayout
        title="Payment Details"
        cancelOrder={true}
        prevStep={prevStep}
        showContinueButton={true}
        disableNextButton={!orderCompleted}
        cohortEaid={cohortEaid}
        order5NUId={order5NUId}
        isCancelOrder5={isCancelOrder5}
        cancelOrder5={cancelOrder5}
        nextStep={async () => {
          if (cohortEaid) {
            try {
              await run(
                client(`cc/order/updateaccountattribute/${cohortEaid}`, {
                  method: 'GET',
                }),
              )
            } catch (err) {
              console.log(err)
            }
          }
          if (!selectedCentersAndApps) {
            processPurchase()
          } else {
            processPurchaseCC()
          }
          if (frontendSideNav) {
            let elem = document.getElementById('app-container')
            elem.style.gridTemplateAreas =
              '"header header" "sidebar main-content" "sidebar footer"'
          }
          if (!isHeaderVisible) {
            setIsHeaderVisible(true)
          }
        }}
        style={processing && {opacity: '0.4', pointerEvents: 'none'}}
      >
        {allowInvoice && frontendCohortRelease && (
          <BillingOptionsModal
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        )}
        {selectedCentersAndApps && (
          <div>
            <CcSidebar
              selectedCentersAndApps={selectedCentersAndApps}
              incentiveCoupon={incentiveCoupon}
              country={countryIsoValue}
              orderCouponCodes={orderCouponCodes}
              eligibleTransitionCenters={eligibleTransitionCenters}
              defaultCentersAndApps={defaultCentersAndApps}
              setCurrentPrice={setCurrentPrice}
              setTotalCcPrice={setTotalCcPrice}
              upgradePaymentPreview={upgradePaymentPreview}
              previewRequestFinished={previewRequestFinished}
              isCohort={isCohort}
              accountInfo={accountInfo}
            />
          </div>
        )}
        {processing && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              color: 'black',
              fontWeight: '600',
              zIndex: '3',
            }}
          >
            <Spinner />
            <span style={{fontSize: '24px'}}>{processingMessage}</span>
          </div>
        )}
        {!selectedCentersAndApps && (
          <div>
            <div style={{paddingLeft: '5px'}}>
              {!isNewOrder && !isCCPlan && (
                <PlanComparisonDetails
                  accountInfo={accountInfo}
                  selectedPlan={selectedPlan}
                  listOfSelectedAddons={selectedAddons}
                  discount={discountAmount + autoCouponDiscount}
                  setCurrentPrice={setCurrentPrice}
                />
              )}
            </div>
            {!isNewOrder && <hr style={{maxWidth: '380px'}} />}
            <CartPaymentDetails
              listOfAddons={selectedAddons}
              totalDiscount={discountAmount + autoCouponDiscount}
              autoCouponInfo={autoCouponInfo}
              orderCouponCodes={orderCouponCodes}
              cartProducts={cartProducts}
              setCartProducts={setCartProducts}
              selectedCountry={selectedCountry}
              currentStep={currentStep}
              monthlyBilling={monthlyBilling}
              semiAnnuallyBilling={semiAnnuallyBilling}
              currentPlanName={accountInfo?.planName}
              isNewOrder={isNewOrder}
              accountInfo={accountInfo}
              upgradeAccountInfo={accountInfo}
            />
          </div>
        )}
        <div
          className={selectedCentersAndApps ? 'pl-4 pr-4 pb-4 mt-4' : 'p-4'}
          style={
            selectedCentersAndApps
              ? {
                  borderLeft: '1px solid #CCCCCC',
                  minHeight: '628px',
                  gridColumnStart: 2,
                  position: 'relative',
                  marginLeft: '40px',
                }
              : {}
          }
        >
          {(isNewOrder || kickoffApptId || creativeApptId) && (
            <CountdownTimer
              countdownTimeMS={TIMESTAMP_60_MINUTES_FROM_NOW.current}
              creativeApptId={creativeApptId}
              kickoffApptId={kickoffApptId}
              setCreativeApptId={setCreativeApptId}
              setKickoffApptId={setKickoffApptId}
              countryIsoValue={countryIsoValue}
              prevStep={prevStep}
              setCreativeCallDate={setCreativeCallDate}
              setOnboardingCallDate={setOnboardingCallDate}
              frontendCcPaymentDetails={selectedCentersAndApps}
              updateApptOnExpire={updateApptOnExpire}
            />
          )}
          {!hideCouponInput && !selectedCentersAndApps && (
            <>
              <CouponContainer>
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Coupon or Promotional Code"
                  aria-label="Coupon or Promotional Code"
                  name="couponCode"
                  withLabel
                  labelType="floating"
                  variant="default"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                />

                {isCouponCodeValid && (
                  <Icon
                    style={{cursor: 'pointer'}}
                    className="ml-3"
                    type="solid"
                    variant="circleCheck"
                    color="#5cb85c"
                    height="30"
                    width="30"
                  />
                )}
                <Button
                  variant="primary"
                  type="button"
                  onClick={validateCoupon}
                >
                  Apply
                </Button>
              </CouponContainer>
            </>
          )}

          <div
            className={`d-flex mb-5 ${
              (kickoffApptId || creativeApptId) &&
              selectedCentersAndApps &&
              'mt-3'
            } ${selectedCentersAndApps ? 'justify-content-between' : 'mt-4'}`}
          >
            <PaymentLink
              contactMethod={contactMethod}
              setContactMethod={setContactMethod}
              mobile={accountInformation.mobile}
              email={clientEmail}
              currentPrice={currentPrice}
              totalCcPrice={totalCcPrice}
              totalPrice={totalPrice}
              bypassSms={bypassSms}
              showEmailStatusLinkOption={showEmailStatusLinkOption}
              setBypassSms={setBypassSms}
              isLinkSent={isLinkSent}
              handleSendLink={
                selectedCentersAndApps
                  ? handleSendLink
                  : handleSubmit(handleSendLink)
              }
              handleResendLink={
                selectedCentersAndApps
                  ? handleResendLink
                  : handleSubmit(handleResendLink)
              }
              handleCheckSendGridData={handleCheckSendGridData}
              showSmsOption={showSmsOption}
              setShwoSmsOption={setShwoSmsOption}
              sendLinkError={sendLinkError}
              shortenedUrl={shortenedUrl}
              is5Order={!!selectedCentersAndApps}
            >
              {selectedCentersAndApps ? (
                <ParagraphText variant="lg" className="font-weight-bold">
                  Payment Link
                </ParagraphText>
              ) : (
                <SendPaymentLinkText
                  variant="lg"
                  className="font-weight-bold mt-5"
                >
                  Send Payment Link
                </SendPaymentLinkText>
              )}
            </PaymentLink>
            {(hideCouponInput || (selectedCentersAndApps && isLinkSent)) &&
              !bypassSms && (
                <TimelineContainer
                  frontendCcPaymentDetails={selectedCentersAndApps}
                >
                  <Timeline variant="small" events={timelineEvents} />
                </TimelineContainer>
              )}
          </div>
          {!selectedCentersAndApps && (
            <>
              {isNewOrder && monthlyBilling && transitionOrder ? (
                <>
                  <TransitionContainer>
                    <h3 style={{textAlign: 'center'}}>
                      <strong>Monthly Overview</strong>
                    </h3>
                    <p>Transition Incentive</p>
                    <li>
                      <ParagraphText variant="reg">
                        <strong>Month 1 </strong> (Transition Incentive: 75%)
                      </ParagraphText>
                      <ParagraphText variant="reg">
                        <strong>
                          {Math.max(
                            monthlyPrice - incentiveEligibleCart * 0.75,
                            0,
                          )}{' '}
                          {!isAus && '+ Tax'}
                        </strong>
                      </ParagraphText>
                    </li>
                    <li>
                      <ParagraphText variant="reg">
                        <strong>Month 2 </strong>(Transition Incentive: 50%)
                      </ParagraphText>
                      <ParagraphText variant="reg">
                        <strong>
                          $
                          {Math.max(
                            monthlyPrice - incentiveEligibleCart * 0.5,
                            0,
                          ).toFixed(2)}{' '}
                          {!isAus && '+ Tax'}
                        </strong>
                      </ParagraphText>
                    </li>
                    <li className="child">
                      <ParagraphText variant="reg">
                        <strong>Month 3 </strong>(Transition Incentive: 25%)
                      </ParagraphText>
                      <ParagraphText variant="reg">
                        <strong>
                          {Math.max(
                            monthlyPrice - incentiveEligibleCart * 0.25,
                            0,
                          )}{' '}
                          {!isAus && '+ Tax'}
                        </strong>
                      </ParagraphText>
                    </li>
                    <li className="child">
                      {' '}
                      <ParagraphText variant="reg">
                        <strong>Month 4+</strong>
                      </ParagraphText>
                      <ParagraphText variant="reg">
                        <strong>
                          {Math.max(monthlyPrice, 0)} {!isAus && '+ Tax'}
                        </strong>
                      </ParagraphText>
                    </li>
                    <li className="child">
                      {' '}
                      <ParagraphText variant="reg">
                        <strong>Due Today</strong>
                      </ParagraphText>
                      <ParagraphText variant="reg">
                        <strong>{formatPrice(Math.max(totalPrice, 0))}</strong>
                        {!isAus && '(est. tax to be calculated)'}
                      </ParagraphText>
                    </li>
                  </TransitionContainer>
                </>
              ) : (
                <div className="d-flex justify-content-between pb-0 pr-4 pl-4 pt-2 m-0 mt-4">
                  <div className="d-flex flex-column">
                    <ParagraphText variant="lg" className="font-weight-bold">
                      {isNewOrder
                        ? 'Total Due Today'
                        : 'New Recurring Subscription Total'}
                    </ParagraphText>
                    {!isNewOrder && (
                      <ParagraphText
                        variant="reg"
                        color="thryv-gray-medium-500"
                        style={{maxWidth: '360px'}}
                      >
                        *For existing subscriptions, today&apos;s charges will
                        only include the prorated amount of the additional
                        services purchased until the next billing cycle.
                      </ParagraphText>
                    )}
                  </div>
                  <div className="text-right">
                    <ParagraphText variant="reg" className="font-weight-bold">
                      {formatPrice(Math.max(totalPrice, 0))}
                    </ParagraphText>
                    {!isAus && (
                      <ParagraphText variant="reg" className="font-weight-bold">
                        {isNewOrder
                          ? '(est. tax to be calculated)'
                          : 'Plus applicable tax'}
                      </ParagraphText>
                    )}
                  </div>
                </div>
              )}
              <hr />
            </>
          )}
          {!selectedCentersAndApps && (
            <div>
              {monthlyBilling && isNewOrder && (
                <>
                  <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                    {!transitionOrder && (
                      <>
                        <div>
                          <ParagraphText
                            variant="lg"
                            color="thryv-gray-light-500"
                          >
                            Total Due Monthly
                          </ParagraphText>
                          <ParagraphText
                            variant="lg"
                            color="thryv-gray-light-500"
                          >
                            Next Payment {monthlyPayDate}
                          </ParagraphText>
                        </div>
                        <ParagraphText
                          variant="reg"
                          color="thryv-gray-light-500"
                        >
                          $ {Math.max(monthlyTotal, 0)}
                          {!isAus &&
                            !isNZ &&
                            '  * excludes taxes and incentives'}
                          {(isAus || isNZ) && '  * excludes incentives'}
                        </ParagraphText>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                    <ParagraphText variant="reg" color="thryv-gray-light-500">
                      * $ {monthlyTotal}/billing period after all incentives
                      expire.
                    </ParagraphText>
                  </div>
                </>
              )}
              {semiAnnuallyBilling && isNewOrder && (
                <>
                  <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                    <div>
                      <ParagraphText variant="lg" color="thryv-gray-light-500">
                        Total Due Semi-Annually
                      </ParagraphText>
                      <ParagraphText variant="lg" color="thryv-gray-light-500">
                        Next Payment {semiAnnuallyPayDate}
                      </ParagraphText>
                    </div>
                    <ParagraphText variant="reg" color="thryv-gray-light-500">
                      $ {Math.max(semiAnnuallyTotal, 0)}
                      {!isAus && !isNZ && '  * excludes taxes and incentives'}
                      {(isAus || isNZ) && '  * excludes incentives'}
                    </ParagraphText>
                  </div>
                  <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                    <ParagraphText variant="reg" color="thryv-gray-light-500">
                      * $ {Math.max(semiAnnuallyTotal, 0)}/billing period after
                      all incentives expire.
                    </ParagraphText>
                  </div>
                </>
              )}
            </div>
          )}
          {isNewOrder && !selectedCentersAndApps && <hr />}
          {selectedCentersAndApps && (
            <BillingPromptContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '12px',
                }}
              >
                <ParagraphText variant="reg" className="m-0">
                  Next Payment: {monthlyPayDate}
                </ParagraphText>
                {transitionOrder && (
                  <ParagraphText variant="sm" className="m-0">
                    *{formatPrice(ccTotal)}/billing period after all incentives
                    expire
                  </ParagraphText>
                )}
              </div>
              <ParagraphText variant="reg">
                {formatPrice(Math.max(nextAmountDue, 0))}
                {!isAus && !isNZ && '  * excludes taxes and incentives'}
                {(isAus || isNZ) && '  * excludes incentives'}
              </ParagraphText>
            </BillingPromptContainer>
          )}
        </div>
      </NewOrderLayout>
    </>
  )
}

export default PaymentDetails
