import React, {useContext} from 'react'
import {Modal} from '@thryvlabs/maverick'
import {ParagraphText as Text, ModalTitle} from '@thryvlabs/maverick'
import {headerContext} from '../context/header-context'
import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {toast} from 'react-toastify'

const cancelCreative = async (
  client,
  creativeApptId,
  countryISOValue,
  setCreativeConfirmed,
  setCreativeCallDate,
) => {
  const data = {
    type: 'creative',
    demoApptId: creativeApptId,
    country: countryISOValue,
  }
  try {
    await client('plans/creative', {data, method: 'DELETE'})
    setCreativeConfirmed(false)
    setCreativeCallDate(null)
  } catch {
    toast.error('error cancelling creative')
  }
}

const cancelOnboard = async (
  client,
  kickoffApptId,
  countryISOValue,
  setOnboardingConfirmed,
  setOnboardingCallDate,
) => {
  const data = {
    type: 'onboarding',
    demoApptId: kickoffApptId,
    country: countryISOValue,
  }

  try {
    await client('plans/onboarding', {data, method: 'DELETE'})
    setOnboardingConfirmed(false)
    setOnboardingCallDate(null)
  } catch {
    toast.error('error cancelling onboarding')
  }
}

const ConfirmGoBackModal = ({
  prevStep,
  altButton,
  creativeApptId,
  kickoffApptId,
  countryISOValue,
  setCreativeConfirmed,
  setOnboardingConfirmed,
  setOnboardingCallDate,
  setCreativeCallDate,
  order5NUId,
  captureOrderNewUpgrade,
  order5OnboardingApptId,
  order5CreativeApptId,
  setOrder5CreativeApptId,
  setOrder5OnboardingApptId,
}) => {
  const {isHeaderVisible, setIsHeaderVisible} = useContext(headerContext)
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })

  async function handleClick() {
    if (creativeApptId) {
      await cancelCreative(
        client,
        creativeApptId,
        countryISOValue,
        setCreativeConfirmed,
        setCreativeCallDate,
      )
      if (order5NUId && order5CreativeApptId) {
        cancelAppointmentOrder('creative', creativeApptId)
      }
    }
    if (kickoffApptId) {
      await cancelOnboard(
        client,
        kickoffApptId,
        countryISOValue,
        setOnboardingConfirmed,
        setOnboardingCallDate,
      )
      // const apptType = data.type === 'onboarding' ? 'onboarding' : 'creative'
      // cancelAppointmentOrder(apptType, kickoffApptId)
      if (order5NUId && order5OnboardingApptId) {
        cancelAppointmentOrder('onboarding', kickoffApptId)
      }
    }
    prevStep()
    if (!isHeaderVisible) {
      setIsHeaderVisible(true)
    }
  }

  const cancelAppointmentOrder = async (appointment_type, apptId) => {
    const appointmentObj = {
      order_id: order5NUId,
      schedule_status: 'Cancelled',
      appointment_type: appointment_type,
      appt_id: apptId,
    }
    try {
      // const data = appointmentObj
      const result = await run(
        client('ordercapture/services/updateScheduledAppointment', {
          data: appointmentObj,
          method: 'PUT',
        }),
      )
      if (result.success) {
        if (appointment_type === 'creative') {
          setOrder5CreativeApptId(null)
        } else if (appointment_type === 'onboarding') {
          setOrder5OnboardingApptId(null)
        }
        const contextData = {
          appointment_type: appointment_type,
          apptId: null,
        }
        captureOrderNewUpgrade(
          'capture-appointment',
          'new-upgrade',
          contextData,
        )
      } else {
        console.log(result.message)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
      if (error.errors) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
    }
  }

  return (
    <Modal
      id="confirm-go-back-modal"
      variant="default"
      btnText="Confirm"
      btnType="primary"
      btnActionText="CONFIRM"
      btnAction={handleClick}
      footer
      action
      footerCancel
      altBtn={altButton}
    >
      <ModalTitle variant="subtitle" size="lg">
        Are you sure you want to go back?
      </ModalTitle>
      <Text variant="reg">
        If you go back, you will have to reschedule the appointment.
      </Text>
    </Modal>
  )
}

export {ConfirmGoBackModal}
